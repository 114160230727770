var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('warning-tip',{attrs:{"code":"TBTITLESPLIT"}}),_c('main-top',{attrs:{"title":"淘宝标题权重选词分析","desc":"","spend":"TBTITLESPLIT"}},[_c('InputView',{attrs:{"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入商品标题"},on:{"sendSearchName":_vm.getSearchName}})],1),_c('bordure-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"","shadow":""}},[_c('div',[_c('div',{staticClass:"control-tool"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.downloadExcel}},[_vm._v("一键导出")])],1),_c('el-table',{staticStyle:{"width":"100%","border":"1px solid #f4f4f4"},attrs:{"data":_vm.dataList,"size":"mini","header-cell-style":{
          background: '#f8f8f8',
          'font-family': 'PingFangSC-Regular',
          color: '#666666 !important',
          'letter-spacing': '0',
          'text-align': 'center',
          'font-weight': 'normal',
        }}},[_c('el-table-column',{attrs:{"align":"center","prop":"word","label":"分词"}}),_c('el-table-column',{attrs:{"align":"center","prop":"tag","label":"标签"}}),_c('el-table-column',{attrs:{"align":"center","prop":"weight","label":"权重"}}),_c('el-table-column',{attrs:{"align":"center","prop":"synonym","label":"同义词"}})],1)],1)]),_c('Introduce',{attrs:{"introduce":_vm.introduce}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }